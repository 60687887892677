import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, inject, Injectable } from '@angular/core';
class AbstractEventPlugin {
  supports(event) {
    return event.includes(this.modifier);
  }
  unwrap(event) {
    return event.split('.').filter(v => !this.modifier.includes(v)).join('.');
  }
}
const GLOBAL_HANDLER = new InjectionToken('[GLOBAL_HANDLER]: Global event target handler', {
  factory: () => {
    const document = inject(DOCUMENT);
    return name => name === 'body' ? document.body : document.defaultView[name] || document.createElement('div');
  }
});
class GlobalEventPlugin extends AbstractEventPlugin {
  constructor() {
    super(...arguments);
    this.handler = inject(GLOBAL_HANDLER);
    this.modifier = '>';
  }
  addEventListener(_, event, handler) {
    return this.manager.addEventListener(this.handler(event.split('>')[0]), event.split('>')?.[1] ?? '', handler);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵGlobalEventPlugin_BaseFactory;
      return function GlobalEventPlugin_Factory(__ngFactoryType__) {
        return (ɵGlobalEventPlugin_BaseFactory || (ɵGlobalEventPlugin_BaseFactory = i0.ɵɵgetInheritedFactory(GlobalEventPlugin)))(__ngFactoryType__ || GlobalEventPlugin);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: GlobalEventPlugin,
      factory: GlobalEventPlugin.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GlobalEventPlugin, [{
    type: Injectable
  }], null, null);
})();
class OptionsEventPlugin extends AbstractEventPlugin {
  constructor() {
    super(...arguments);
    this.modifier = 'capture.once.passive';
  }
  supports(event) {
    return event.includes('.') && !this.unwrap(event).includes('.');
  }
  addEventListener(element, event, handler) {
    element.addEventListener(this.unwrap(event), handler, {
      once: event.includes('.once'),
      passive: event.includes('.passive'),
      capture: event.includes('.capture')
    });
    return () => element.removeEventListener(this.unwrap(event), handler, event.includes('.capture'));
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵOptionsEventPlugin_BaseFactory;
      return function OptionsEventPlugin_Factory(__ngFactoryType__) {
        return (ɵOptionsEventPlugin_BaseFactory || (ɵOptionsEventPlugin_BaseFactory = i0.ɵɵgetInheritedFactory(OptionsEventPlugin)))(__ngFactoryType__ || OptionsEventPlugin);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: OptionsEventPlugin,
      factory: OptionsEventPlugin.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OptionsEventPlugin, [{
    type: Injectable
  }], null, null);
})();
class PreventEventPlugin extends AbstractEventPlugin {
  constructor() {
    super(...arguments);
    this.modifier = '.prevent';
  }
  addEventListener(element, event, handler) {
    return this.manager.addEventListener(element, this.unwrap(event), event => {
      event.preventDefault();
      handler(event);
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵPreventEventPlugin_BaseFactory;
      return function PreventEventPlugin_Factory(__ngFactoryType__) {
        return (ɵPreventEventPlugin_BaseFactory || (ɵPreventEventPlugin_BaseFactory = i0.ɵɵgetInheritedFactory(PreventEventPlugin)))(__ngFactoryType__ || PreventEventPlugin);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PreventEventPlugin,
      factory: PreventEventPlugin.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PreventEventPlugin, [{
    type: Injectable
  }], null, null);
})();
class ResizePlugin extends AbstractEventPlugin {
  constructor() {
    super(...arguments);
    this.modifier = 'resize';
  }
  supports(event) {
    return event === 'resize';
  }
  addEventListener(element, _, handler) {
    if (typeof ResizeObserver === 'undefined' || !(element instanceof Element)) {
      return () => {};
    }
    const observer = new ResizeObserver(e => this.manager.getZone().run(() => handler(e)));
    observer.observe(element);
    return () => observer.disconnect();
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵResizePlugin_BaseFactory;
      return function ResizePlugin_Factory(__ngFactoryType__) {
        return (ɵResizePlugin_BaseFactory || (ɵResizePlugin_BaseFactory = i0.ɵɵgetInheritedFactory(ResizePlugin)))(__ngFactoryType__ || ResizePlugin);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ResizePlugin,
      factory: ResizePlugin.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizePlugin, [{
    type: Injectable
  }], null, null);
})();
class SelfEventPlugin extends AbstractEventPlugin {
  constructor() {
    super(...arguments);
    this.modifier = '.self';
  }
  addEventListener(element, event, handler) {
    return this.manager.addEventListener(element, this.unwrap(event), event => {
      if (event.target === event.currentTarget) {
        handler(event);
      }
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵSelfEventPlugin_BaseFactory;
      return function SelfEventPlugin_Factory(__ngFactoryType__) {
        return (ɵSelfEventPlugin_BaseFactory || (ɵSelfEventPlugin_BaseFactory = i0.ɵɵgetInheritedFactory(SelfEventPlugin)))(__ngFactoryType__ || SelfEventPlugin);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SelfEventPlugin,
      factory: SelfEventPlugin.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SelfEventPlugin, [{
    type: Injectable
  }], null, null);
})();
class SilentEventPlugin extends AbstractEventPlugin {
  constructor() {
    super(...arguments);
    this.modifier = '.silent';
  }
  addEventListener(element, event, handler) {
    SilentEventPlugin.ngZone = this.manager.getZone();
    return SilentEventPlugin.ngZone.runOutsideAngular(() => this.manager.addEventListener(element, this.unwrap(event), handler));
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵSilentEventPlugin_BaseFactory;
      return function SilentEventPlugin_Factory(__ngFactoryType__) {
        return (ɵSilentEventPlugin_BaseFactory || (ɵSilentEventPlugin_BaseFactory = i0.ɵɵgetInheritedFactory(SilentEventPlugin)))(__ngFactoryType__ || SilentEventPlugin);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SilentEventPlugin,
      factory: SilentEventPlugin.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SilentEventPlugin, [{
    type: Injectable
  }], null, null);
})();
class StopEventPlugin extends AbstractEventPlugin {
  constructor() {
    super(...arguments);
    this.modifier = '.stop';
  }
  addEventListener(element, event, handler) {
    return this.manager.addEventListener(element, this.unwrap(event), event => {
      event.stopPropagation();
      handler(event);
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵStopEventPlugin_BaseFactory;
      return function StopEventPlugin_Factory(__ngFactoryType__) {
        return (ɵStopEventPlugin_BaseFactory || (ɵStopEventPlugin_BaseFactory = i0.ɵɵgetInheritedFactory(StopEventPlugin)))(__ngFactoryType__ || StopEventPlugin);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: StopEventPlugin,
      factory: StopEventPlugin.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StopEventPlugin, [{
    type: Injectable
  }], null, null);
})();
const PLUGINS = [SilentEventPlugin, GlobalEventPlugin, OptionsEventPlugin, PreventEventPlugin, ResizePlugin, SelfEventPlugin, StopEventPlugin];
const NG_EVENT_PLUGINS = PLUGINS.map(useClass => ({
  provide: EVENT_MANAGER_PLUGINS,
  multi: true,
  useClass
}));
function shouldCall(predicate) {
  return (_target, _key, desc) => {
    const {
      value
    } = desc;
    desc.value = function (...args) {
      if (!predicate.apply(this, args)) {
        return;
      }
      if (SilentEventPlugin.ngZone) {
        SilentEventPlugin.ngZone.run(() => value.apply(this, args));
      } else {
        value.apply(this, args);
      }
    };
  };
}
function asCallable(a) {
  return a;
}

/**
 * Generated bundle index. Do not edit.
 */

export { GLOBAL_HANDLER, GlobalEventPlugin, NG_EVENT_PLUGINS, OptionsEventPlugin, PreventEventPlugin, ResizePlugin, SilentEventPlugin, StopEventPlugin, asCallable, shouldCall };
