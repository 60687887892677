import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Injectable, Directive, Optional, SkipSelf, inject, Input, INJECTOR, Component, ChangeDetectionStrategy, TemplateRef, DestroyRef, ChangeDetectorRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EMPTY_CLIENT_RECT } from '@taiga-ui/cdk/constants';
import { TuiHoveredService } from '@taiga-ui/cdk/directives/hovered';
import { tuiInjectElement, tuiPointToClientRect } from '@taiga-ui/cdk/utils/dom';
import { tuiClamp } from '@taiga-ui/cdk/utils/math';
import { tuiCreateTokenFromFactory, tuiCreateToken, tuiProvide, tuiPx, tuiPure, tuiIsPresent } from '@taiga-ui/cdk/utils/miscellaneous';
import { tuiParentAnimation, tuiFadeIn } from '@taiga-ui/core/animations';
import { TuiDriverDirective, TuiDriver, tuiAsDriver, TuiPositionAccessor, tuiFallbackAccessor, TuiRectAccessor, tuiAsRectAccessor, tuiAsVehicle, tuiPositionAccessorFor, tuiRectAccessorFor } from '@taiga-ui/core/classes';
import { TuiPositionService, TuiVisualViewportService } from '@taiga-ui/core/services';
import { TUI_VIEWPORT, TUI_ANIMATIONS_SPEED } from '@taiga-ui/core/tokens';
import { tuiOverrideOptions, tuiIsObscured, tuiToAnimationOptions } from '@taiga-ui/core/utils';
import { PolymorpheusComponent, POLYMORPHEUS_CONTEXT, PolymorpheusOutlet, PolymorpheusTemplate } from '@taiga-ui/polymorpheus';
import { BehaviorSubject, Subject, merge, switchMap, of, delay, takeUntil, repeat, filter, map, tap, takeWhile, distinctUntilChanged, fromEvent, debounce, timer, startWith, skip } from 'rxjs';
import { TuiActiveZone } from '@taiga-ui/cdk/directives/active-zone';
import { DOCUMENT, NgForOf } from '@angular/common';
import { tuiIfMap, tuiTypedFromEvent, tuiZoneOptimized } from '@taiga-ui/cdk/observables';
import { tuiIsNativeFocused } from '@taiga-ui/cdk/utils/focus';

/**
 * A component to display a hint
 */
function TuiHintUnstyledComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c0 = ["*"];
function TuiHintComponent_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 1);
  }
  if (rf & 2) {
    const text_r1 = ctx.polymorpheusOutlet;
    i0.ɵɵproperty("innerHTML", text_r1, i0.ɵɵsanitizeHtml);
  }
}
const _c1 = a0 => ({
  $implicit: a0
});
function TuiHints_div_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiHints_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtemplate(1, TuiHints_div_0_ng_container_1_Template, 1, 0, "ng-container", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const hint_r1 = ctx.$implicit;
    i0.ɵɵproperty("@tuiParentAnimation", undefined)("tuiActiveZoneParent", hint_r1.activeZone || null);
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", hint_r1.component)("polymorpheusOutletContext", i0.ɵɵpureFunction1(4, _c1, hint_r1));
  }
}
const TUI_HINT_COMPONENT = tuiCreateTokenFromFactory(() => TuiHintComponent);

/**
 * Service for displaying hints/tooltips
 */
class TuiHintService extends BehaviorSubject {
  constructor() {
    super([]);
  }
  add(directive) {
    this.next(this.value.concat(directive));
  }
  remove(directive) {
    if (this.value.includes(directive)) {
      this.next(this.value.filter(hint => hint !== directive));
    }
  }
  static {
    this.ɵfac = function TuiHintService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiHintService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TuiHintService,
      factory: TuiHintService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class TuiHintDriver extends TuiDriverDirective {
  constructor() {
    super(...arguments);
    this.type = 'hint';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiHintDriver_BaseFactory;
      return function TuiHintDriver_Factory(__ngFactoryType__) {
        return (ɵTuiHintDriver_BaseFactory || (ɵTuiHintDriver_BaseFactory = i0.ɵɵgetInheritedFactory(TuiHintDriver)))(__ngFactoryType__ || TuiHintDriver);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiHintDriver,
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintDriver, [{
    type: Directive,
    args: [{
      standalone: true
    }]
  }], null, null);
})();
const TUI_HINT_DIRECTIONS = ['bottom-left', 'bottom', 'bottom-right', 'top-left', 'top', 'top-right', 'left-top', 'left', 'left-bottom', 'right-top', 'right', 'right-bottom'];
/** Default values for hint options */
const TUI_HINT_DEFAULT_OPTIONS = {
  direction: 'bottom-left',
  showDelay: 500,
  hideDelay: 200,
  appearance: '',
  icon: '@tui.circle-help'
};
/**
 * Default parameters for hint directive
 */
const TUI_HINT_OPTIONS = tuiCreateToken(TUI_HINT_DEFAULT_OPTIONS);
const tuiHintOptionsProvider = override => ({
  provide: TUI_HINT_OPTIONS,
  deps: [[new Optional(), TuiHintOptionsDirective], [new Optional(), new SkipSelf(), TUI_HINT_OPTIONS]],
  useFactory: tuiOverrideOptions(override, TUI_HINT_DEFAULT_OPTIONS)
});
/**
 * @deprecated: drop in 5.0
 */
class TuiHintOptionsDirective {
  constructor() {
    this.options = inject(TUI_HINT_OPTIONS, {
      skipSelf: true
    });
    this.direction = this.options.direction;
    this.appearance = this.options.appearance;
    this.showDelay = this.options.showDelay;
    this.hideDelay = this.options.hideDelay;
    this.icon = this.options.icon;
    this.change$ = new Subject();
  }
  ngOnChanges() {
    this.change$.next();
  }
  static {
    this.ɵfac = function TuiHintOptionsDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiHintOptionsDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiHintOptionsDirective,
      selectors: [["", "tuiHintContent", ""]],
      inputs: {
        content: [0, "tuiHintContent", "content"],
        direction: [0, "tuiHintDirection", "direction"],
        appearance: [0, "tuiHintAppearance", "appearance"],
        showDelay: [0, "tuiHintShowDelay", "showDelay"],
        hideDelay: [0, "tuiHintHideDelay", "hideDelay"]
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiProvide(TUI_HINT_OPTIONS, TuiHintOptionsDirective)]), i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintOptionsDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiHintContent]',
      providers: [tuiProvide(TUI_HINT_OPTIONS, TuiHintOptionsDirective)]
    }]
  }], null, {
    content: [{
      type: Input,
      args: ['tuiHintContent']
    }],
    direction: [{
      type: Input,
      args: ['tuiHintDirection']
    }],
    appearance: [{
      type: Input,
      args: ['tuiHintAppearance']
    }],
    showDelay: [{
      type: Input,
      args: ['tuiHintShowDelay']
    }],
    hideDelay: [{
      type: Input,
      args: ['tuiHintHideDelay']
    }]
  });
})();
class TuiHintHover extends TuiDriver {
  constructor() {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.el = tuiInjectElement();
    this.hovered$ = inject(TuiHoveredService);
    this.options = inject(TUI_HINT_OPTIONS);
    this.visible = false;
    this.toggle$ = new Subject();
    this.stream$ = merge(this.toggle$.pipe(switchMap(visible => of(visible).pipe(delay(visible ? 0 : this.tuiHintHideDelay))), takeUntil(this.hovered$), repeat()), this.hovered$.pipe(switchMap(visible => of(visible).pipe(delay(visible ? this.tuiHintShowDelay : this.tuiHintHideDelay))), takeUntil(this.toggle$), repeat())).pipe(filter(() => this.enabled), map(value => value && (this.el.hasAttribute('tuiHintPointer') || !tuiIsObscured(this.el))), tap(visible => {
      this.visible = visible;
    }));
    this.parent = inject(TuiHintHover, {
      optional: true,
      skipSelf: true
    });
    this.tuiHintShowDelay = this.options.showDelay;
    this.tuiHintHideDelay = this.options.hideDelay;
    this.type = 'hint';
    this.enabled = true;
  }
  toggle(visible = !this.visible) {
    this.toggle$.next(visible);
    this.parent?.toggle(visible);
  }
  static {
    this.ɵfac = function TuiHintHover_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiHintHover)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiHintHover,
      inputs: {
        tuiHintShowDelay: "tuiHintShowDelay",
        tuiHintHideDelay: "tuiHintHideDelay"
      },
      exportAs: ["tuiHintHover"],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiAsDriver(TuiHintHover), TuiHoveredService]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintHover, [{
    type: Directive,
    args: [{
      standalone: true,
      providers: [tuiAsDriver(TuiHintHover), TuiHoveredService],
      exportAs: 'tuiHintHover'
    }]
  }], function () {
    return [];
  }, {
    tuiHintShowDelay: [{
      type: Input
    }],
    tuiHintHideDelay: [{
      type: Input
    }]
  });
})();
const OFFSET = 8;
const ARROW_OFFSET = 22;
const TOP = 0;
const LEFT = 1;
class TuiHintPosition extends TuiPositionAccessor {
  constructor() {
    super(...arguments);
    this.viewport = inject(TUI_VIEWPORT);
    this.accessor = tuiFallbackAccessor('hint')(inject(TuiRectAccessor), inject(TuiHintDirective));
    this.points = TUI_HINT_DIRECTIONS.reduce((acc, direction) => ({
      ...acc,
      [direction]: [0, 0]
    }), {});
    this.direction = inject(TUI_HINT_OPTIONS).direction;
    this.type = 'hint';
  }
  getPosition({
    width,
    height
  }) {
    const hostRect = this.accessor.getClientRect() ?? EMPTY_CLIENT_RECT;
    const leftCenter = hostRect.left + hostRect.width / 2;
    const topCenter = hostRect.top + hostRect.height / 2;
    this.points['top-left'][TOP] = hostRect.top - height - OFFSET;
    this.points['top-left'][LEFT] = leftCenter - width + ARROW_OFFSET;
    this.points.top[TOP] = this.points['top-left'][TOP];
    this.points.top[LEFT] = leftCenter - width / 2;
    this.points['top-right'][TOP] = this.points['top-left'][TOP];
    this.points['top-right'][LEFT] = leftCenter - ARROW_OFFSET;
    this.points['bottom-left'][TOP] = hostRect.bottom + OFFSET;
    this.points['bottom-left'][LEFT] = this.points['top-left'][LEFT];
    this.points.bottom[TOP] = this.points['bottom-left'][TOP];
    this.points.bottom[LEFT] = this.points.top[LEFT];
    this.points['bottom-right'][TOP] = this.points['bottom-left'][TOP];
    this.points['bottom-right'][LEFT] = this.points['top-right'][LEFT];
    this.points['left-top'][TOP] = topCenter - height + ARROW_OFFSET;
    this.points['left-top'][LEFT] = hostRect.left - width - OFFSET;
    this.points.left[TOP] = topCenter - height / 2;
    this.points.left[LEFT] = this.points['left-top'][LEFT];
    this.points['left-bottom'][TOP] = topCenter - ARROW_OFFSET;
    this.points['left-bottom'][LEFT] = this.points['left-top'][LEFT];
    this.points['right-top'][TOP] = this.points['left-top'][TOP];
    this.points['right-top'][LEFT] = hostRect.right + OFFSET;
    this.points.right[TOP] = this.points.left[TOP];
    this.points.right[LEFT] = this.points['right-top'][LEFT];
    this.points['right-bottom'][TOP] = this.points['left-bottom'][TOP];
    this.points['right-bottom'][LEFT] = this.points['right-top'][LEFT];
    if (this.checkPosition(this.points[this.direction], width, height)) {
      return this.points[this.direction];
    }
    const direction = TUI_HINT_DIRECTIONS.find(direction => this.checkPosition(this.points[direction], width, height));
    return this.points[direction || this.fallback];
  }
  get fallback() {
    return this.points.top[TOP] > this.viewport.getClientRect().bottom - this.points.bottom[TOP] ? 'top' : 'bottom';
  }
  checkPosition([top, left], width, height) {
    const viewport = this.viewport.getClientRect();
    return top > OFFSET / 4 && left > OFFSET / 4 && top + height < viewport.bottom - OFFSET / 4 && left + width < viewport.right - OFFSET / 4;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiHintPosition_BaseFactory;
      return function TuiHintPosition_Factory(__ngFactoryType__) {
        return (ɵTuiHintPosition_BaseFactory || (ɵTuiHintPosition_BaseFactory = i0.ɵɵgetInheritedFactory(TuiHintPosition)))(__ngFactoryType__ || TuiHintPosition);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiHintPosition,
      inputs: {
        direction: [0, "tuiHintDirection", "direction"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintPosition, [{
    type: Directive,
    args: [{
      standalone: true
    }]
  }], null, {
    direction: [{
      type: Input,
      args: ['tuiHintDirection']
    }]
  });
})();
class TuiHintDirective {
  constructor() {
    this.service = inject(TuiHintService);
    this.appearance = inject(TUI_HINT_OPTIONS).appearance;
    this.component = inject(PolymorpheusComponent);
    this.el = tuiInjectElement();
    this.activeZone = inject(TuiActiveZone, {
      optional: true
    });
    this.type = 'hint';
  }
  set tuiHint(content) {
    this.content = content;
    if (!content) {
      this.toggle(false);
    }
  }
  ngOnDestroy() {
    this.toggle(false);
  }
  getClientRect() {
    return this.el.getBoundingClientRect();
  }
  toggle(show) {
    if (show && this.content) {
      this.service.add(this);
    } else {
      this.service.remove(this);
    }
  }
  static {
    this.ɵfac = function TuiHintDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiHintDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiHintDirective,
      selectors: [["", "tuiHint", "", 5, "ng-container", 5, "ng-template"]],
      inputs: {
        context: [0, "tuiHintContext", "context"],
        appearance: [0, "tuiHintAppearance", "appearance"],
        tuiHint: "tuiHint"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiAsRectAccessor(TuiHintDirective), tuiAsVehicle(TuiHintDirective), {
        provide: PolymorpheusComponent,
        deps: [TUI_HINT_COMPONENT, INJECTOR],
        useClass: PolymorpheusComponent
      }]), i0.ɵɵHostDirectivesFeature([TuiHintDriver, {
        directive: TuiHintHover,
        inputs: ["tuiHintHideDelay", "tuiHintHideDelay", "tuiHintShowDelay", "tuiHintShowDelay"]
      }, {
        directive: TuiHintPosition,
        inputs: ["tuiHintDirection", "tuiHintDirection"]
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiHint]:not(ng-container):not(ng-template)',
      providers: [tuiAsRectAccessor(TuiHintDirective), tuiAsVehicle(TuiHintDirective), {
        provide: PolymorpheusComponent,
        deps: [TUI_HINT_COMPONENT, INJECTOR],
        useClass: PolymorpheusComponent
      }],
      hostDirectives: [TuiHintDriver, {
        directive: TuiHintHover,
        inputs: ['tuiHintHideDelay', 'tuiHintShowDelay']
      }, {
        directive: TuiHintPosition,
        inputs: ['tuiHintDirection']
      }]
    }]
  }], null, {
    context: [{
      type: Input,
      args: ['tuiHintContext']
    }],
    appearance: [{
      type: Input,
      args: ['tuiHintAppearance']
    }],
    tuiHint: [{
      type: Input
    }]
  });
})();
class TuiHintPointer extends TuiHintHover {
  constructor() {
    super(...arguments);
    this.currentRect = EMPTY_CLIENT_RECT;
  }
  getClientRect() {
    return this.currentRect;
  }
  onMove({
    clientX,
    clientY
  }) {
    this.currentRect = tuiPointToClientRect(clientX, clientY);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiHintPointer_BaseFactory;
      return function TuiHintPointer_Factory(__ngFactoryType__) {
        return (ɵTuiHintPointer_BaseFactory || (ɵTuiHintPointer_BaseFactory = i0.ɵɵgetInheritedFactory(TuiHintPointer)))(__ngFactoryType__ || TuiHintPointer);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiHintPointer,
      selectors: [["", "tuiHint", "", "tuiHintPointer", ""]],
      hostBindings: function TuiHintPointer_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mousemove.silent", function TuiHintPointer_mousemove_silent_HostBindingHandler($event) {
            return ctx.onMove($event);
          });
        }
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiAsRectAccessor(TuiHintPointer), tuiAsDriver(TuiHintPointer)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintPointer, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiHint][tuiHintPointer]',
      providers: [tuiAsRectAccessor(TuiHintPointer), tuiAsDriver(TuiHintPointer)],
      host: {
        '(mousemove.silent)': 'onMove($event)'
      }
    }]
  }], null, null);
})();
class TuiHintUnstyledComponent {
  constructor() {
    this.context = inject(POLYMORPHEUS_CONTEXT);
  }
  static {
    this.ɵfac = function TuiHintUnstyledComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiHintUnstyledComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiHintUnstyledComponent,
      selectors: [["ng-component"]],
      hostVars: 1,
      hostBindings: function TuiHintUnstyledComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵsyntheticHostProperty("@tuiParentAnimation", undefined);
        }
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 1,
      consts: [[4, "polymorpheusOutlet"]],
      template: function TuiHintUnstyledComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TuiHintUnstyledComponent_ng_container_0_Template, 1, 0, "ng-container", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("polymorpheusOutlet", ctx.context.$implicit.content);
        }
      },
      dependencies: [PolymorpheusOutlet],
      encapsulation: 2,
      data: {
        animation: [tuiParentAnimation]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintUnstyledComponent, [{
    type: Component,
    args: [{
      standalone: true,
      imports: [PolymorpheusOutlet],
      template: '<ng-container *polymorpheusOutlet="context.$implicit.content" />',
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [tuiParentAnimation],
      host: {
        '[@tuiParentAnimation]': ''
      }
    }]
  }], null, null);
})();
class TuiHintUnstyled {
  constructor() {
    const hint = inject(TuiHintDirective);
    hint.component = new PolymorpheusComponent(TuiHintUnstyledComponent);
    hint.content = inject(TemplateRef);
  }
  static {
    this.ɵfac = function TuiHintUnstyled_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiHintUnstyled)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiHintUnstyled,
      selectors: [["ng-template", "tuiHint", ""]],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintUnstyled, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: 'ng-template[tuiHint]'
    }]
  }], function () {
    return [];
  }, null);
})();
const GAP = 4;
const TUI_HINT_PROVIDERS = [TuiPositionService, TuiHoveredService, tuiPositionAccessorFor('hint', TuiHintPosition), tuiRectAccessorFor('hint', TuiHintDirective)];
class TuiHintComponent {
  constructor() {
    this.el = tuiInjectElement();
    this.hover = inject(TuiHintHover);
    this.vvs = inject(TuiVisualViewportService);
    this.viewport = inject(TUI_VIEWPORT);
    this.options = tuiToAnimationOptions(inject(TUI_ANIMATIONS_SPEED));
    this.pointer = inject(TuiHintPointer, {
      optional: true
    });
    this.accessor = inject(TuiRectAccessor);
    this.hint = inject(POLYMORPHEUS_CONTEXT).$implicit;
    this.appearance = this.hint.appearance || this.hint.el.closest('[tuiTheme]')?.getAttribute('tuiTheme');
    inject(TuiPositionService).pipe(takeWhile(() => this.hint.el.isConnected), map(point => this.vvs.correct(point)), takeUntilDestroyed()).subscribe({
      next: ([top, left]) => this.update(top, left),
      complete: () => this.hover.toggle(false)
    });
    inject(TuiHoveredService).pipe(takeUntilDestroyed()).subscribe(hover => this.hover.toggle(hover));
  }
  get content() {
    return this.hint.component.component === TuiHintUnstyledComponent ? '' : this.hint.content;
  }
  onClick(target) {
    if (!target.closest('tui-hint') && !this.hint.el.contains(target) || tuiIsObscured(this.hint.el)) {
      this.hover.toggle(false);
    }
  }
  apply(top, left, beakTop, beakLeft) {
    this.el.style.top = top;
    this.el.style.left = left;
    this.el.style.setProperty('--top', beakTop);
    this.el.style.setProperty('--left', beakLeft);
  }
  update(top, left) {
    const {
      height,
      width
    } = this.el.getBoundingClientRect();
    const rect = this.accessor.getClientRect();
    const viewport = this.viewport.getClientRect();
    if (rect === EMPTY_CLIENT_RECT || !height || !width) {
      return;
    }
    const safeLeft = tuiClamp(left, GAP, viewport.width - width - GAP);
    const [beakTop, beakLeft] = this.vvs.correct([rect.top + rect.height / 2 - top, rect.left + rect.width / 2 - safeLeft]);
    this.apply(tuiPx(Math.round(top)), tuiPx(Math.round(safeLeft)), tuiPx(Math.round(tuiClamp(beakTop, 1, height - 1))), tuiPx(Math.round(tuiClamp(beakLeft, 1, width - 1))));
  }
  static {
    this.ɵfac = function TuiHintComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiHintComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiHintComponent,
      selectors: [["tui-hint"]],
      hostVars: 5,
      hostBindings: function TuiHintComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function TuiHintComponent_click_HostBindingHandler($event) {
            return ctx.onClick($event.target);
          }, false, i0.ɵɵresolveDocument);
        }
        if (rf & 2) {
          i0.ɵɵsyntheticHostProperty("@tuiFadeIn", ctx.options);
          i0.ɵɵattribute("data-appearance", ctx.appearance)("tuiTheme", ctx.appearance);
          i0.ɵɵclassProp("_untouchable", ctx.pointer);
        }
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature(TUI_HINT_PROVIDERS), i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 2,
      vars: 2,
      consts: [[3, "innerHTML", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], [3, "innerHTML"]],
      template: function TuiHintComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
          i0.ɵɵtemplate(1, TuiHintComponent_span_1_Template, 1, 1, "span", 0);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("polymorpheusOutlet", ctx.content)("polymorpheusOutletContext", ctx.hint.context);
        }
      },
      dependencies: [PolymorpheusOutlet],
      styles: ["[_nghost-%COMP%]{position:absolute;max-inline-size:18rem;min-block-size:var(--tui-height-m);padding:.75rem 1rem;background:var(--tui-background-accent-1);border-radius:var(--tui-radius-l);color:var(--tui-text-primary-on-accent-1);box-sizing:border-box;font:var(--tui-font-text-s);white-space:pre-line;word-wrap:break-word;line-height:1.25rem}[_nghost-%COMP%]:before{content:\"\";position:absolute;top:var(--top);left:var(--left);inline-size:.5rem;block-size:.5rem;border-radius:.125rem;box-sizing:border-box;background:inherit;transform:translate(-50%,-50%) rotate(45deg)}[data-appearance=error][_nghost-%COMP%]{background:var(--tui-status-negative)}[data-appearance=dark][_nghost-%COMP%]{background:var(--tui-background-accent-opposite-hover);color:var(--tui-background-base);filter:drop-shadow(0 0 .125rem rgba(0,0,0,.16)) drop-shadow(0 1.5rem 1rem rgba(0,0,0,.03)) drop-shadow(0 .75rem .75rem rgba(0,0,0,.04)) drop-shadow(0 .25rem .375rem rgba(0,0,0,.05))}[_nghost-%COMP%]:not([style*=top]){visibility:hidden}._untouchable[_nghost-%COMP%]{pointer-events:none}"],
      data: {
        animation: [tuiFadeIn]
      },
      changeDetection: 0
    });
  }
}
__decorate([tuiPure], TuiHintComponent.prototype, "apply", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-hint',
      imports: [PolymorpheusOutlet],
      template: `
        <ng-content />
        <span
            *polymorpheusOutlet="content as text; context: hint.context"
            [innerHTML]="text"
        ></span>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: TUI_HINT_PROVIDERS,
      animations: [tuiFadeIn],
      host: {
        '[@tuiFadeIn]': 'options',
        '[class._untouchable]': 'pointer',
        '[attr.data-appearance]': 'appearance',
        '[attr.tuiTheme]': 'appearance',
        '(document:click)': 'onClick($event.target)'
      },
      styles: [":host{position:absolute;max-inline-size:18rem;min-block-size:var(--tui-height-m);padding:.75rem 1rem;background:var(--tui-background-accent-1);border-radius:var(--tui-radius-l);color:var(--tui-text-primary-on-accent-1);box-sizing:border-box;font:var(--tui-font-text-s);white-space:pre-line;word-wrap:break-word;line-height:1.25rem}:host:before{content:\"\";position:absolute;top:var(--top);left:var(--left);inline-size:.5rem;block-size:.5rem;border-radius:.125rem;box-sizing:border-box;background:inherit;transform:translate(-50%,-50%) rotate(45deg)}:host[data-appearance=error]{background:var(--tui-status-negative)}:host[data-appearance=dark]{background:var(--tui-background-accent-opposite-hover);color:var(--tui-background-base);filter:drop-shadow(0 0 .125rem rgba(0,0,0,.16)) drop-shadow(0 1.5rem 1rem rgba(0,0,0,.03)) drop-shadow(0 .75rem .75rem rgba(0,0,0,.04)) drop-shadow(0 .25rem .375rem rgba(0,0,0,.05))}:host:not([style*=top]){visibility:hidden}:host._untouchable{pointer-events:none}\n"]
    }]
  }], function () {
    return [];
  }, {
    apply: []
  });
})();
class TuiHintDescribe extends TuiDriver {
  constructor() {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.doc = inject(DOCUMENT);
    this.el = tuiInjectElement();
    this.id$ = new BehaviorSubject('');
    this.stream$ = this.id$.pipe(distinctUntilChanged(), tuiIfMap(() => fromEvent(this.doc, 'keydown', {
      capture: true
    }), tuiIsPresent), switchMap(() => this.focused ? of(false) : merge(tuiTypedFromEvent(this.doc, 'keyup'), tuiTypedFromEvent(this.element, 'blur')).pipe(map(() => this.focused))), debounce(visible => visible ? timer(1000) : of(null)), startWith(false), distinctUntilChanged(), skip(1), tuiZoneOptimized());
    this.type = 'hint';
  }
  set tuiHintDescribe(id) {
    this.id$.next(id || '');
  }
  get element() {
    return this.doc.getElementById(this.id$.value || '') || this.el;
  }
  get focused() {
    return tuiIsNativeFocused(this.element);
  }
  static {
    this.ɵfac = function TuiHintDescribe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiHintDescribe)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiHintDescribe,
      selectors: [["", "tuiHintDescribe", ""]],
      inputs: {
        tuiHintDescribe: "tuiHintDescribe"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiAsDriver(TuiHintDescribe)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
__decorate([tuiPure], TuiHintDescribe.prototype, "element", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintDescribe, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiHintDescribe]',
      providers: [tuiAsDriver(TuiHintDescribe)]
    }]
  }], function () {
    return [];
  }, {
    tuiHintDescribe: [{
      type: Input
    }],
    element: []
  });
})();
class TuiHintHost extends TuiRectAccessor {
  constructor() {
    super(...arguments);
    this.type = 'hint';
  }
  getClientRect() {
    return this.tuiHintHost?.getBoundingClientRect() || EMPTY_CLIENT_RECT;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiHintHost_BaseFactory;
      return function TuiHintHost_Factory(__ngFactoryType__) {
        return (ɵTuiHintHost_BaseFactory || (ɵTuiHintHost_BaseFactory = i0.ɵɵgetInheritedFactory(TuiHintHost)))(__ngFactoryType__ || TuiHintHost);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiHintHost,
      selectors: [["", "tuiHint", "", "tuiHintHost", ""]],
      inputs: {
        tuiHintHost: "tuiHintHost"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiAsRectAccessor(TuiHintHost)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintHost, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiHint][tuiHintHost]',
      providers: [tuiAsRectAccessor(TuiHintHost)]
    }]
  }], null, {
    tuiHintHost: [{
      type: Input
    }]
  });
})();
class TuiHintManual extends TuiDriver {
  constructor() {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.hover = inject(TuiHintHover);
    this.stream$ = new BehaviorSubject(false);
    this.tuiHintManual = false;
    this.type = 'hint';
    this.hover.enabled = false;
  }
  ngOnChanges() {
    this.stream$.next(this.tuiHintManual);
  }
  static {
    this.ɵfac = function TuiHintManual_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiHintManual)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiHintManual,
      selectors: [["", "tuiHint", "", "tuiHintManual", ""]],
      inputs: {
        tuiHintManual: "tuiHintManual"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiAsDriver(TuiHintManual)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintManual, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiHint][tuiHintManual]',
      providers: [tuiAsDriver(TuiHintManual)]
    }]
  }], function () {
    return [];
  }, {
    tuiHintManual: [{
      type: Input
    }]
  });
})();
class TuiHintOverflow {
  constructor() {
    this.hint = inject(TuiHintDirective);
  }
  onMouseEnter({
    scrollWidth,
    clientWidth,
    textContent
  }) {
    this.hint.tuiHint = scrollWidth > clientWidth ? textContent : '';
  }
  static {
    this.ɵfac = function TuiHintOverflow_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiHintOverflow)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiHintOverflow,
      selectors: [["", "tuiHintOverflow", ""]],
      hostBindings: function TuiHintOverflow_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mouseenter", function TuiHintOverflow_mouseenter_HostBindingHandler($event) {
            return ctx.onMouseEnter($event.currentTarget);
          });
        }
      },
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([{
        directive: TuiHintDirective,
        inputs: ["tuiHintAppearance", "tuiHintAppearance"]
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintOverflow, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiHintOverflow]',
      hostDirectives: [{
        directive: TuiHintDirective,
        inputs: ['tuiHintAppearance']
      }],
      host: {
        '(mouseenter)': 'onMouseEnter($event.currentTarget)'
      }
    }]
  }], null, null);
})();
const TuiHint = [TuiHintComponent, TuiHintDirective, TuiHintOptionsDirective, TuiHintUnstyled, TuiHintDriver, TuiHintPosition, TuiHintHover, TuiHintOverflow, TuiHintDescribe, TuiHintHost, TuiHintManual, TuiHintPointer];
class TuiHints {
  constructor() {
    this.hints$ = inject(TuiHintService);
    this.destroyRef = inject(DestroyRef);
    this.cdr = inject(ChangeDetectorRef);
    this.hints = [];
  }
  ngOnInit() {
    // Due to this view being parallel to app content, `markForCheck` from `async` pipe
    // can happen after view was checked, so calling `detectChanges` instead
    this.hints$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(hints => {
      this.hints = hints;
      this.cdr.detectChanges();
    });
  }
  static {
    this.ɵfac = function TuiHints_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiHints)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiHints,
      selectors: [["tui-hints"]],
      hostAttrs: ["aria-live", "polite"],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 1,
      consts: [["role", "tooltip", 3, "tuiActiveZoneParent", 4, "ngFor", "ngForOf"], ["role", "tooltip", 3, "tuiActiveZoneParent"], [4, "polymorpheusOutlet", "polymorpheusOutletContext"]],
      template: function TuiHints_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TuiHints_div_0_Template, 2, 6, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngForOf", ctx.hints);
        }
      },
      dependencies: [NgForOf, TuiActiveZone, PolymorpheusOutlet],
      styles: ["[_nghost-%COMP%]{position:fixed;top:0;left:0;inline-size:100%;block-size:100%;block-size:0}"],
      data: {
        animation: [tuiParentAnimation]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHints, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-hints',
      imports: [NgForOf, TuiActiveZone, PolymorpheusOutlet, PolymorpheusTemplate],
      changeDetection: ChangeDetectionStrategy.Default,
      animations: [tuiParentAnimation],
      host: {
        'aria-live': 'polite'
      },
      template: "<div\n    *ngFor=\"let hint of hints\"\n    role=\"tooltip\"\n    @tuiParentAnimation\n    [tuiActiveZoneParent]=\"hint.activeZone || null\"\n>\n    <ng-container *polymorpheusOutlet=\"hint.component; context: {$implicit: hint}\" />\n</div>\n",
      styles: [":host{position:fixed;top:0;left:0;inline-size:100%;block-size:100%;block-size:0}\n"]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_HINT_COMPONENT, TUI_HINT_DEFAULT_OPTIONS, TUI_HINT_DIRECTIONS, TUI_HINT_OPTIONS, TUI_HINT_PROVIDERS, TuiHint, TuiHintComponent, TuiHintDescribe, TuiHintDirective, TuiHintDriver, TuiHintHost, TuiHintHover, TuiHintManual, TuiHintOptionsDirective, TuiHintOverflow, TuiHintPointer, TuiHintPosition, TuiHintService, TuiHintUnstyled, TuiHintUnstyledComponent, TuiHints, tuiHintOptionsProvider };
